import { throttle } from './throttle.js';

function setCustomVH() {
  const vh = window.innerHeight * 0.01; // 1% от высоты экрана
  const elements = document.querySelectorAll('.full-height');

  elements.forEach(element => {
    element.style.setProperty('--vh', `${vh}px`); // Устанавливаем переменную для каждого элемента
  });
}

function setFullHeight() {
  const elements = document.querySelectorAll('.full-height');
  elements.forEach(element => {
    element.style.minHeight = `calc(var(--vh, 100vh) * 100)`; // Используем кастомную переменную
  });
}

// Устанавливаем значение при загрузке
document.addEventListener('DOMContentLoaded', () => {
  setCustomVH(); // Установить vh сразу
  setFullHeight(); // Установить полную высоту элементов
});

// Оборачиваем в throttle
const throttledSetFullHeight = throttle(() => {
  setCustomVH(); // Обновляем vh
  setFullHeight(); // Устанавливаем полную высоту
}, 250);

