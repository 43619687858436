
window.addEventListener('scroll', checkItemsScrollTop);

function checkItemsScrollTop() {

  setScrollClassToHeader();
  checkIntersection();

}

function setScrollClassToHeader() {

  let scrollTop = window.scrollY;
  let header = document.querySelector('header');

  if(scrollTop < 1){
    header.classList.remove('scroll');
    return;
  }
  header.classList.add('scroll');

}


let yandexItem = document.querySelector('.yandex-item');
const secondBlock = document.querySelector('.promo');

function checkIntersection() {
  const boxRect = yandexItem.getBoundingClientRect();
  const blockRect = secondBlock.getBoundingClientRect();

  if (
    boxRect.bottom > blockRect.top &&
    boxRect.top < blockRect.bottom &&
    boxRect.right > blockRect.left &&
    boxRect.left < blockRect.right
  ) {
    yandexItem.classList.remove('scroll');
  } else {
    yandexItem.classList.add('scroll');
  }
}
