import JustValidate from 'just-validate';
import Inputmask from "../../../node_modules/inputmask/dist/inputmask.es6.js";
import { addTokenToForms } from '../_functions.js';
import GraphModal from 'graph-modal';

import { enableScroll } from './enable-scroll.js';

const modals = new GraphModal();

export const validateForms = (selector, rules, checkboxes = [], afterSend) => {
  const form = document?.querySelector(selector);
  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.log(form)
    console.error('Форма не найдена!');
    return;
  }

  form._submit_ = function () {

    form._validation_.revalidate().then(async isValid => {

      if (!isValid) return grecaptcha.reset();

      const pageName = document.querySelector('[data-page-name]').getAttribute('data-page-name');
      const inputPageName = document.createElement('input');

      inputPageName.setAttribute('type', 'hidden');
      inputPageName.setAttribute('name', 'pagename');

      inputPageName.value = pageName;

      form.prepend(inputPageName);

      let formData = new FormData(form);
      let xhr = new XMLHttpRequest();

      const formButton = form.querySelector('.btn-submit')

      const modalOpenElement = document.querySelector('.graph-modal-open');

      xhr.onreadystatechange = function () {

        formButton.setAttribute('disabled', '');

        if (xhr.readyState === 4) {
          try {

            const response = JSON.parse(xhr.responseText);

            if (response.status === 'success') {

              modals.close();

              modals.open('form_send-succesful');

              form.reset();
              grecaptcha.reset();

              addTokenToForms(form);

              formButton.removeAttribute('disabled');

              sendMetrics();

            } else {

              modals.close();

              modals.open('form_send-error');

              form.reset();
              grecaptcha.reset();

              formButton.removeAttribute('disabled');

              if (modalOpenElement) {
                modalOpenElement.classList.remove('graph-modal-open');
              }

            }

          } catch (e) {

          }
        }

      };

      xhr.open('POST', 'mail.php', true);
      xhr.send(formData);

      enableScroll();

    });

  }

  if (telSelector) {
    const inputMask = new Inputmask('+7 (999) 999-99-99');
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: item.telError
        });
      }
    }
  }

  const validation = new JustValidate(selector);
  form._validation_ = validation;

  for (let item of rules) {
    validation
      .addField(item.ruleSelector, item.rules);
  }

  if (checkboxes.length) {
    for (let item of checkboxes) {
      validation
        .addRequiredGroup(
          `${item.selector}`,
          `${item.errorMessage}`
        )
    }
  }

  function sendMetrics() {
    ym(99140079,'reachGoal','all_forms');

    if(form.classList.contains('form-3')) ym(99140079,'reachGoal','send_popap');
    if(form.classList.contains('form-2')) ym(99140079,'reachGoal','send_form_garant');
    if(form.classList.contains('form-1')) ym(99140079,'reachGoal','send_promo');
  };
};
