const openDoctorSlides = document.querySelectorAll('.doctors__slide-item');

openDoctorSlides.forEach((slide) => {

  slide.addEventListener('click', () => {

    const modal = document.querySelector('.doctor-pop');

    const modalInfo = {
      name: modal.querySelector('.doctor-pop__title'),
      titul: modal.querySelector('.doctor-pop__titul'),
      ul: modal.querySelector('.doctor-pop__ul'),
      time: modal.querySelector('.doctor-pop__time'),
      img: modal.querySelector('.doctor-pop__img-file'),
      link: modal.querySelector('.doctor-pop__button'),
    };

    modalInfo.name.textContent = slide.querySelector('.doctors__slide-item__about-name').textContent;
    modalInfo.titul.textContent = slide.querySelector('.doctors__slide-item__about-profession').textContent;
    modalInfo.ul.innerHTML = slide.querySelector('.doctors__slide-item__ul').innerHTML;
    modalInfo.time.textContent = slide.querySelector('.doctors__slide-item__time').textContent;
    modalInfo.img.src = slide.querySelector('.doctors__slide-item__img').getAttribute('src');
    modalInfo.link.href = slide.querySelector('.doctors__slide-item__link').getAttribute('href');

  });
});
